import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Page from "../components/Page";
import SEO from "../components/SEO";

import { main } from "../helpers/navigation";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      adImg: file(relativePath: { eq: "index/Main_HomeImg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 474, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Page
      page="Index"
      pageName="Головна"
      title="Мережа інформаційно-рекламних моніторів у багатоквартирних будинках"
      parameters={main}
    >
      <SEO title="Головна" />
      <div className="Index_block">
        <h6 className="title">
          <Link to="/homeowners/solutions">Домовласникам</Link>
        </h6>
        <ul className="benefits-list">
          <li>Актуальна інформація</li>
          <li>Робота з боржниками</li>
          <li>Дохід</li>
          <li>Повний сервіс</li>
          <li>Чистота і порядок</li>
          <li>Сучасно</li>
        </ul>
        <span className="more">
          <Link to="/homeowners/solutions">детальніше...</Link>
        </span>
      </div>
      <div className="Index_block ads">
        <Img
          fluid={data.adImg.childImageSharp.fluid}
          className="Index_block__img"
        />
      </div>
      <div className="Index_block">
        <h6 className="title">
          <Link to="/adv/solutions">Рекламодавцям</Link>
        </h6>
        <ul className="benefits-list">
          <li>Велика аудиторія</li>
          <li>В фокусі уваги</li>
          <li>Економно</li>
          <li>Зручно та швидко</li>
          <li>Ефективно</li>
          <li>Сучасно</li>
        </ul>
        <span className="more">
          <Link to="/adv/solutions">детальніше...</Link>
        </span>
      </div>
    </Page>
  );
};

export default IndexPage;
